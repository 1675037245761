import React from "react";
import { Container, Row, Col } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import Button from "@ui/button";
import {
  BlockChainSupportWrapper,
} from "./style";

export default function BlockChainSupport({ data }) {
  return (
    <BlockChainSupportWrapper id={data.section}>
      <Container>
        <Row>
          <Col lg={12}>
            <SectionTitle
              subtitle={data.section_title.subtitle}
              title={data.section_title.title}
              description={data.section_title.description}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            {data.buttons &&
              data?.buttons?.map(({ id, content, ...rest }) => (
                <Button key={id} m="7px" {...rest}>
                  {content}
                </Button>
              ))}
          </Col>
        </Row>
      </Container>
    </BlockChainSupportWrapper>
  );
}
