import styled from "@styled";

export const BlockChainSupportWrapper = styled.section`
  padding: 100px 0;
  background: #fa4f19;
  .section-title {
    margin-bottom: 40px;

    h6 {
      color: #fff;
    }
    h2 {
      border-color: #fff;
      margin-bottom: 50px;
    }
    p {
      color: #fff;
    }
  }

  a {
    color: #212121;
    
    &:hover {
      color: inherit;
    }
  }
`;
